
import AVIframe from '@/components/AVIframe.vue';
import Store from '@/store';
import { IPartnerSEOProductResult, IProductList, getPartnerDetailsResult } from '@homeserve/od-typings/api';
import { productListHelper, seoHelper } from '@homeserve/vue-api-plugin';
import WLHomeFormDiagnosisOddJobs from '@homeserve/vue-components-plugin/src/components/WLHome/WLHomeFormDiagnosisOddJobs.vue';
import GTMPageViewingMixin from '@homeserve/vue-components-plugin/src/mixins/GTMPageViewingMixin';
import { formatPhone } from '@homeserve/vue-components-plugin/src/utils/phoneHelper';
import { Component, Mixins } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component({
  components: {
    AVIframe,
    WLHomeFormDiagnosisOddJobs,
    WLAVReinsurance: () => import('@homeserve/vue-components-plugin/src/components/WLHome/WLAVReinsurance.vue'),
    WLProductList: () => import('@homeserve/vue-components-plugin/src/components/WLHome/WLProductList.vue'),
    WLNewCustomerRatings: () =>
      import('@homeserve/vue-components-plugin/src/components/WLHome/WLNewCustomerRatings.vue'),
    WLHomeServeBlock: () => import('@homeserve/vue-components-plugin/src/components/WLHomepage/WLHomeServeBlock.vue'),
    WLHomeServeExpert: () => import('@homeserve/vue-components-plugin/src/components/WLHomepage/WLHomeServeExpert.vue'),
    WLHomepageProductsSeo: () =>
      import('@homeserve/vue-components-plugin/src/components/WLSeo/WLHomepageProductsSeo.vue'),
  },
})
export default class Home extends Mixins(GTMPageViewingMixin) {
  public i18n: any = null;

  @Getter('seoProducts', { namespace: 'api/catalog/seo' })
  private seoProducts!: IPartnerSEOProductResult[];

  @Getter('productListsForPartner', { namespace: 'api/catalog/productList' })
  private productLists!: IProductList[];

  @Getter('partner')
  private partner!: getPartnerDetailsResult;

  get supportPhone(): string {
    return formatPhone(this.partner.supportPhone, '0', true);
  }

  get oddJobs() {
    return this.productLists.find(list => list.name === 'petits-travaux');
  }

  get topRepairs() {
    return this.productLists.find(list => list.name === 'top-depannages');
  }

  public async beforeRouteEnter(to, from, next) {
    await productListHelper.fetchProductListsForCurrentPartner(Store);
    await seoHelper.fetchSeos(Store);
    return next();
  }

  created() {
    this.i18n = {
      reinsurance: { headline: this.$t('reinsurance.headline') },
    };
  }
}
