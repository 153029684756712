import { render, staticRenderFns } from "./AVIframe.vue?vue&type=template&id=a8089330&scoped=true"
import script from "./AVIframe.vue?vue&type=script&lang=ts"
export * from "./AVIframe.vue?vue&type=script&lang=ts"
import style0 from "./AVIframe.vue?vue&type=style&index=0&id=a8089330&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a8089330",
  null
  
)

export default component.exports